import { API } from '@/utility/Api'
import type { Conversation } from 'types/Conversation'
import { reactive, ref, type Ref } from 'vue'
import {
  Identity,
  Meeting,
  Vue,
  Log,
  LocalVideoTrack,
  UserMedia,
  DisplayMedia,
  type MeetingStateChangeEvent
} from '@liveswitch/sdk'
import type { UserDetails } from 'types/UserDetails'
import { SourceType, TextCommands } from '@/utility/Constants'
import type ManagerStateChangeEvent from 'node_modules/@liveswitch/sdk/models/ManagerStateChangeEvent'
import UAParser from 'ua-parser-js'
import { Session } from './Session'
import { setContext } from '@/bubble'
import { addBreadcrumb, captureException } from '@sentry/vue'

export interface CallState {
  audioMuted: boolean
  videoMuted: boolean
  screenShare: boolean
  conversationId: string
}

export class IdentityError extends Error {
  public status: number

  constructor(message: string, status: number) {
    super(message)
    this.name = 'IdentityError'
    this.status = status

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, IdentityError.prototype)
  }
}

export class LiveswitchMeeting {
  private conversation: Conversation
  private user: UserDetails | undefined
  public meeting: Meeting | undefined = undefined
  private api: API
  private container: HTMLDivElement | undefined = undefined
  public userMedia: UserMedia = new UserMedia(true, true)
  public displayMedia: DisplayMedia = new DisplayMedia(true, true)
  private backoffTimer = 0
  private maxRetries = 15
  private retries = 0
  private currentCallState: CallState

  constructor(conversation: Conversation, startWithVideo: boolean, user?: UserDetails) {
    this.conversation = conversation
    this.user = user
    this.api = new API()
    Vue.reactive = reactive
    Log.level = import.meta.env.VITE_LS2_LOG_LEVEL || 'info'
    this.currentCallState = Session.getCallState(conversation.id) || {
      audioMuted: false,
      videoMuted: !startWithVideo,
      screenShare: false,
      conversationId: conversation.id
    }
    setContext('meetingContext', this)
  }

  private async getLSIdentity() {
    try {
      addBreadcrumb({
        category: 'LiveSwitchMeeting',
        message: 'Getting LS Identity.',
        level: 'info'
      })
      const response = await this.api.postAuth('/public/token', {
        conversationId: this.conversation.id,
        conversationToken: this.conversation.token
      })
      addBreadcrumb({
        category: 'LiveSwitchMeeting',
        message: 'Received LS Identity.',
        level: 'info'
      })
      return new Identity({
        type: 'anonymous',
        apiKey: response.data.apiKey,
        identityServiceUrl: response.data.clusterUrl
      })
    } catch (e) {
      captureException(e)
      throw e
    }
  }

  public async start(container: HTMLDivElement, identity: Identity | null = null) {
    this.container = container
    try {
      addBreadcrumb({
        category: 'LiveSwitchMeeting',
        message: 'Starting meeting.',
        level: 'info'
      })
      if (
        this.meeting &&
        (this.meeting.state === 'joining' ||
          this.meeting.state === 'joined' ||
          this.meeting.state === 'reconnecting')
      ) {
        addBreadcrumb({
          category: 'LiveSwitchMeeting',
          message: 'Meeting already found.',
          level: 'info'
        })
        return this.meeting
      }
      /* Try and connect even if API has a moment or the user has a poor connection. */
      while (!identity && this.retries < this.maxRetries) {
        try {
          await new Promise((resolve) => setTimeout(resolve, this.backoffTimer))
          identity = await this.getLSIdentity()
          if (!identity) {
            throw new Error('Identity not found')
          }
        } catch (e) {
          this.retries++
          this.backoffTimer *= 2 // Exponential backoff
          if (this.retries >= this.maxRetries) {
            throw new Error('Max retries reached.')
          }
        }
      }
      if (!identity) {
        throw new Error('Identity not found')
      }
      this.meeting = new Meeting({
        identity: identity,
        uploadClientConfiguration: {
          MaxFileLength: 104857600,
          MaxConcurrentUploads: 1,
          MaxConcurrentDownloads: 1,
          MaxInlineContentLength: 1048576,
          StorageServiceUrlBase: ''
        }
      })
      this.meeting.setLocalUserMedia(this.userMedia)
      this.meeting.setLocalDisplayMedia(this.displayMedia)
      this.meeting.maxRetries = 45
      this.meeting.requestTimeout = 10000
      this.meeting.maxVisibleUserMedias = 2
      setContext('meeting', this.meeting)
      addBreadcrumb({
        category: 'LiveSwitchMeeting',
        message: 'Joining meeting.',
        level: 'info'
      })
      try {
        await this.meeting.join({
          displayName: `${this.user?.firstName ?? 'Customer'} ${this.user?.lastName ?? ''}`,
          roomKey: this.conversation.roomKey,
          useAttendeeList: true,
          useChat: true,
          useCamera: true,
          useMicrophone: true,
          useScreenShare: true,
          useRemoteMedia: true,
          localUserOptions: { customBandwidthAdaptationEnabled: true }
        })
      } catch (e) {
        captureException(e)
        this.start(container, identity)
        return
      }
      addBreadcrumb({
        category: 'LiveSwitchMeeting',
        message: 'Joined meeting.',
        level: 'info'
      })

      this.meeting.stateChanged.bind(async (args: MeetingStateChangeEvent) => {
        addBreadcrumb({
          category: 'LiveSwitchMeeting',
          message: `Meeting state has changed to ${args.state} from ${args.previousState}`,
          level: 'info'
        })
        // If you have disconnected from the meeting, attempt to rejoin the meeting.
        if (args.meeting.hasFailed && args.state === 'left') {
          // I am aware if the video was started after they joined and then disconnect they will rejoin with no video again.
          await this.start(
            container,
            identity // Prevent creating a new identity for the meeting when you already have one.
          )
        }
      })

      try {
        //@ts-ignore
        LocalVideoTrack.prototype.setFacingMode = function (facingMode) {
          //@ts-ignore
          this._facingMode = facingMode
        }
        if (this.userMedia.videoTrack) {
          //@ts-ignore
          this.userMedia.videoTrack.setFacingMode('user')
        }
      } catch (e) {
        captureException(e)
      }

      if (!this.currentCallState.videoMuted) {
        await this.userMedia.start()
      } else {
        await this.userMedia.startAudio()
        // This is a hack in place until stats are fixed.
        if (this.meeting?.originUserManager.state !== 'started') {
          this.meeting?.originUserManager.stateChanged.bind(
            async (args: ManagerStateChangeEvent) => {
              if (args.state === 'started') {
                await this.meeting?.localAttendee.muteVideo()
              }
            }
          )
        } else {
          await this.meeting?.localAttendee.muteVideo()
        }
      }

      // LiveSwitch Meetings only reconnect after a very short delay,
      // Otherwise you need to create a whole new meeting.
      // The goal here is to make it seem like you reconnected even if you did a rull tear down and connect.
      setContext('currentCallState', this.currentCallState)
      if (this.currentCallState.audioMuted) {
        await this.userMedia.muteAudio()
      } else {
        await this.userMedia.unmuteAudio()
      }
      if (this.currentCallState.videoMuted) {
        await this.userMedia.muteVideo()
      } else {
        await this.userMedia.unmuteVideo()
      }
      if (this.currentCallState.screenShare) {
        await this.displayMedia.start()
      }

      await this.meeting.localAttendee.addSetting({
        attendeeId: this.meeting.localAttendee.id,
        name: 'Recording:HideIfVideoMuted'
      })
      await this.meeting.localAttendee.addSetting({
        attendeeId: this.meeting.localAttendee.id,
        name: 'Recording:IsFeatured'
      })
      // The defaultChannel is currently down due to maintaince and causing our app to be down instead of just snapshots.
      try {
        this.meeting.chat.defaultChannel.messageReceived.bind(async (event) => {
          const message = event.message
          setContext('message', message)
          if (message.type === 'TEXT') {
            addBreadcrumb({
              category: 'LiveSwitchMeeting',
              message: `Received message from chat: ${message.text}`,
              level: 'info'
            })
            await this.handleIncomingTextMessage(message.text)
          }
        })
      } catch (e) {}

      const isMobile = () => {
        const { device } = UAParser(navigator.userAgent)
        return !!device.type // Desktops never have a type.
      }
      let wasVideoActive = false
      document.addEventListener('visibilitychange', () => {
        try {
          if (document.visibilityState === 'hidden' && isMobile()) {
            wasVideoActive =
              this.userMedia.videoTrack.isStarted && !this.userMedia.videoTrack.isMuted
            if (wasVideoActive) {
              addBreadcrumb({
                category: 'LiveSwitchMeeting',
                message: 'Visibility changed, muting video (mobile).',
                level: 'info'
              })
              this.userMedia.muteVideo()
            }
          } else if (document.visibilityState == 'visible' && isMobile()) {
            if (wasVideoActive) {
              addBreadcrumb({
                category: 'LiveSwitchMeeting',
                message: 'Visibility changed, unmuting video (mobile).',
                level: 'info'
              })
              this.userMedia.unmuteVideo()
            }
          }
        } catch (e) {}
      })

      const handleBeforeUnload = async () => {
        try {
          await this.meeting?.leave()
        } catch (e) {}
        window.removeEventListener('beforeunload', handleBeforeUnload)
      }
      window.addEventListener('beforeunload', handleBeforeUnload)

      addBreadcrumb({
        category: 'LiveSwitchMeeting',
        message: 'Finished starting meeting.',
        level: 'info'
      })

      return this.meeting
    } catch (e) {
      captureException(e)
      throw e
    }
  }

  public async stop() {
    try {
      if (this.userMedia) {
        await this.userMedia.stop()
      }
      if (this.displayMedia) {
        await this.displayMedia.stop()
      }
      if (this.meeting) {
        await this.meeting.leave()
        this.meeting = undefined
      }
    } catch (e) {
      captureException(e)
    }
  }

  private async handleIncomingTextMessage(text: string) {
    switch (text) {
      case TextCommands.TAKE_SNAPSHOT:
        try {
          addBreadcrumb({
            category: 'LiveSwitchMeeting',
            message: 'Taking snapshot.',
            level: 'info'
          })
          await this.takeSnapshot()
          addBreadcrumb({
            category: 'LiveSwitchMeeting',
            message: 'Successfully took snapshot.',
            level: 'info'
          })
        } catch (e) {
          captureException(e)
          await this.meeting?.chat.defaultChannel.send(TextCommands.SNAPSHOT_FAILED)
        }
        break
    }
  }
  public async takeSnapshot() {
    const media = this.userMedia
    if (!media || !media.videoTrack || !media.videoTrack.isStarted) {
      throw new Error(`Video not enabled for user.`)
    }
    if (!this.conversation) {
      throw new Error(`Conversation not found.`)
    }
    const localMedia: HTMLVideoElement | null | undefined = this.container?.querySelector(
      '.liveswitch-local-webcam-video'
    )
    if (!localMedia) {
      throw new Error(`Video not enabled for user.`)
    }
    const canvas = document.createElement('canvas')
    canvas.width = localMedia?.videoWidth
    canvas.height = localMedia?.videoHeight
    canvas.getContext('2d')?.drawImage(localMedia, 0, 0, canvas.width, canvas.height)
    canvas.toBlob(async (blob) => {
      if (blob) {
        let formData = new FormData()
        formData.append('snapshot', blob)
        const resp = await this.api.postAuth(`/public/snapshots/${this.conversation.id}/`, formData)
        if (resp.ok) {
          await this.meeting?.chat.defaultChannel.send(TextCommands.SNAPSHOT_SUCCESS)
        } else {
          await this.meeting?.chat.defaultChannel.send(TextCommands.SNAPSHOT_FAILED)
        }
      } else {
        await this.meeting?.chat.defaultChannel.send(TextCommands.SNAPSHOT_FAILED)
      }
    }, 'image/png')
  }
  public async toggleAudioMuted() {
    try {
      if (this.userMedia.audioTrack.isMuted) {
        await this.userMedia.unmuteAudio()
      } else {
        await this.userMedia.muteAudio()
      }
      this.currentCallState.audioMuted = this.userMedia.audioTrack.isMuted
    } catch (e) {
      captureException(e)
    } finally {
      Session.setCallState(this.currentCallState)
      setContext('currentCallState', this.currentCallState)
    }
  }
  public async toggleVideoMuted() {
    try {
      if (!this.userMedia.videoTrack.isStarted) {
        await this.userMedia.startVideo()
        await this.meeting?.localAttendee.unmuteVideo()
        await this.userMedia.unmuteVideo()
        this.currentCallState.videoMuted = false
        return
      }
      if (this.userMedia.videoTrack.isMuted) {
        await this.userMedia.unmuteVideo()
      } else {
        await this.userMedia.muteVideo()
      }
      this.currentCallState.videoMuted = this.userMedia.videoTrack.isMuted
    } catch (e) {
      captureException(e)
    } finally {
      Session.setCallState(this.currentCallState)
      setContext('currentCallState', this.currentCallState)
    }
  }
  public async toggleScreenShare() {
    try {
      if (this.displayMedia.isStopping || this.displayMedia.isStopped) {
        addBreadcrumb({
          category: 'LiveSwitchMeeting',
          message: 'Starting screen share.',
          level: 'info'
        })
        await this.displayMedia.start()
        this.currentCallState.screenShare = true
      } else {
        addBreadcrumb({
          category: 'LiveSwitchMeeting',
          message: 'Stopping screen share.',
          level: 'info'
        })
        await this.displayMedia.stop()
        this.currentCallState.screenShare = false
      }
    } catch (e) {
      captureException(e)
    } finally {
      Session.setCallState(this.currentCallState)
      setContext('currentCallState', this.currentCallState)
    }
  }
}
