<template>
  <div
    class="flex min-h-full flex-1 flex-col justify-center items-center px-1 py-1 sm:px-8 sm:py-8 bg-white w-full"
  >
    <div class="md:max-w-md w-full">
      <h2 class="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
        {{ headlineText }}
      </h2>
      <div class="mt-5">
        <form class="space-y-6" :disabled="formDisabled" ref="inputForm">
          <template v-if="!$props.formSubmission">
            <template v-for="(field, index) in $props.form.FormFields" :key="field.id">
              <div
                class="rounded-md pl-3 pr-0.5 pb-0.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600"
                @click="(ev: MouseEvent) => focusInput(ev)"
              >
                <label for="name" class="block text-xs font-bold text-gray-900">{{
                  field.name
                }}</label>
                <input
                  v-if="field.type === FieldType.Text"
                  type="text"
                  :placeholder="field.placeholder"
                  :name="field.id"
                  v-model.trim="data[field.id]"
                  @change="
                    (event: any) =>
                      checkRulesAgainstData(validationRules.get(field.id) ?? [], field.id)
                  "
                  class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                />
                <input
                  v-if="field.type === FieldType.Email"
                  type="email"
                  :placeholder="field.placeholder"
                  :name="field.id"
                  v-model.trim="data[field.id]"
                  @change="
                    (event: any) =>
                      checkRulesAgainstData(validationRules.get(field.id) ?? [], field.id)
                  "
                  class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                />
                <input
                  v-if="field.type === FieldType.Phone"
                  type="tel"
                  :placeholder="field.placeholder"
                  :name="field.id"
                  v-model.trim="data[field.id]"
                  @change="
                    (event: any) => {
                      adjustTelephoneValue(event as KeyboardEvent, field.id)
                      checkRulesAgainstData(validationRules.get(field.id) ?? [], field.id)
                    }
                  "
                  class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                />
                <input
                  v-if="field.type === FieldType.Date"
                  type="date"
                  :placeholder="field.placeholder"
                  :name="field.id"
                  v-model.trim="data[field.id]"
                  class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  @change="
                    (event: any) =>
                      checkRulesAgainstData(validationRules.get(field.id) ?? [], field.id)
                  "
                />
                <input
                  v-if="field.type === FieldType.Number"
                  v-model.trim="data[field.id]"
                  :placeholder="field.placeholder"
                  :name="field.id"
                  type="text"
                  class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  @change="
                    (event: any) =>
                      checkRulesAgainstData(validationRules.get(field.id) ?? [], field.id)
                  "
                />
                <textarea
                  v-if="field.type === FieldType.TextArea"
                  v-model.trim="data[field.id]"
                  :name="field.id"
                  class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 min-h-8 resize-y"
                  @change="
                    (event: any) =>
                      checkRulesAgainstData(validationRules.get(field.id) ?? [], field.id)
                  "
                >
                </textarea>
                <div
                  v-if="failedData[field.id]"
                  class="border-t-red-600 text-xs text-red-600 border-t pt-1"
                >
                  {{ failedData[field.id] }}
                </div>
              </div>
            </template>
          </template>
          <template v-if="$props.use === 'Missed'">
            <div
              class="rounded-md pl-3 pr-0.5 pb-0.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600"
            >
              <label for="message" class="block text-xs font-bold text-gray-900">Message:</label>
              <textarea
                name="message"
                v-model.trim="data.message"
                maxlength="250"
                class="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 min-h-8 resize-y"
              >
              </textarea>
              <div v-if="failedData.message" class="mt-1 text-xs text-red-600">
                {{ failedData.message }}
              </div>
            </div>
          </template>
          <button
            class="w-full rounded-full bg-indigo-600 px-4 py-4 text-md font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            @click.prevent="submit"
          >
            {{ $props.use === 'Form' ? 'Start Conversation' : 'Send Message' }}
          </button>
          <button
            class="w-full rounded-full bg-red-600 px-4 py-4 text-md font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
            @click="leave"
            v-if="$props.sourceType === SourceType.KIOSK"
          >
            Back To Start
          </button>
        </form>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { Form } from 'types/Form'
import type { FormSubmission } from 'types/FormSubmission'
import type { FormSubmissionField } from 'types/FormSubmissionField'
import { VuetifyValidation, type ValidationRule } from '@/utility/Validation'
import { FieldType } from '@/enums/FieldType'
import { FieldValidationType } from '@/enums/FieldValidationType'
import {
  type Ref,
  ref,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  type ComputedRef,
  computed,
  onErrorCaptured
} from 'vue'
import type { FormField } from 'types/FormField'
import { FieldDataType } from '@/enums/FieldDataType'
import type { QRCode } from 'types/QRCode'
import type { Kiosk } from 'types/Kiosk'
import type { Website } from 'types/Website'
import { SourceType } from '@/utility/Constants'
import type { BubbleConfig } from 'types/BubbleConfig'
import type { UserDetails } from 'types/UserDetails'
import { DefaultFieldNames } from '@/enums/DefaultFieldNames'
import {
  AsYouType,
  parsePhoneNumber,
  getCountryCallingCode,
  type CountryCode
} from 'libphonenumber-js'
import { captureException } from '@/bubble'
const props = defineProps<{
  use: 'Form' | 'Missed'
  config: BubbleConfig
  form: Form
  formSubmission: FormSubmission | undefined
  model: QRCode | Kiosk | Website
  sourceType: SourceType
  userDetails?: UserDetails
}>()
const emits = defineEmits<{
  (e: 'transition'): void
  (e: 'formSubmit', data: FormSubmission): void
  (e: 'userDetails', data: UserDetails): void
  (e: 'close'): void
}>()
const data: Ref<any> = ref({})
const failedData: Ref<any> = ref({})
const emailOrPhoneFields: Set<string> = new Set()
const formDisabled: Ref<boolean> = ref(false)
let defaultCountryCode: CountryCode = 'US'
let inputForm: Ref<any> = ref()

const headlineText: ComputedRef<string> = computed<string>(() => {
  if (props.use === 'Form') {
    if (props.sourceType === SourceType.WEBSITE) {
      return props.config?.templateWelcome || props.form.headline || "Fill in your details and start the call!"
    } else {
      return props.form.headline || props.config?.templateWelcome || "Fill in your details and start the call!"
    }
  } else {
    return props.config?.templateMissedCall || props.form.headline || "Sorry, we missed you. Please leave your query below."
  }
})
const CheckEmailAndPhone = (errorTpl: string): ValidationRule => {
  return {
    isValid(value: string) {
      if (emailOrPhoneFields.size === 0) {
        return true
      }
      for (let fieldId of emailOrPhoneFields) {
        if (data.value[fieldId].trim() !== '') {
          for (let fieldId of emailOrPhoneFields) {
            if (failedData.value[fieldId] === errorTpl) {
              failedData.value[fieldId] = ''
            }
          }
          return true
        }
      }
      for (let fieldId of emailOrPhoneFields) {
        failedData.value[fieldId] = errorTpl
      }
      return errorTpl
    }
  }
}
const adjustTelephoneValue = (e: KeyboardEvent, fieldId: string) => {
  if (!defaultCountryCode) {
    defaultCountryCode = 'US'
  }
  let formatted = new AsYouType(defaultCountryCode).input((e.target as HTMLInputElement)?.value)
  try {
    const phoneNumber = parsePhoneNumber(formatted, defaultCountryCode)
    if (phoneNumber && phoneNumber.isValid()) {
      formatted = phoneNumber.formatInternational()
      data.value[fieldId] = formatted
    }
  } catch (error) {
  } finally {
    if (data.value[fieldId].indexOf('+') === -1 && data.value[fieldId]) {
      const possibleCountryCode = getCountryCallingCode(defaultCountryCode)
      data.value[fieldId] = `+${possibleCountryCode} ${data.value[fieldId]}`
    }
  }
}
function isValidNumber(str: string): boolean {
  if (str.trim() === '') {
    return false // Empty or whitespace-only strings are not valid numbers
  }
  //@ts-ignore
  return !isNaN(str)
}
const validationRules: Map<string, Array<ValidationRule>> = new Map<string, Array<ValidationRule>>()
if (!props.formSubmission) {
  for (let field of props.form.FormFields) {
    const rules: ValidationRule[] = validationRules.get(field.id) ?? []
    data.value[field.id] = ''
    if (!field.canBeDeleted) {
      if (
        field.name.toLocaleLowerCase() === DefaultFieldNames.NameField.toLowerCase() &&
        (props.userDetails?.firstName || props.userDetails?.lastName)
      ) {
        let name: Array<string> = []
        if (props.userDetails.firstName) {
          name.push(props.userDetails.firstName)
        }
        if (props.userDetails.lastName) {
          name.push(props.userDetails.lastName)
        }
        data.value[field.id] = name.join(' ')
      } else if (
        field.name.toLocaleLowerCase() === DefaultFieldNames.EmailAddressField.toLowerCase() &&
        props.userDetails?.emailAddress
      ) {
        data.value[field.id] = props.userDetails?.emailAddress
      } else if (
        field.name.toLocaleLowerCase() === DefaultFieldNames.PhoneField.toLowerCase() &&
        props.userDetails?.phoneNumber
      ) {
        //@ts-ignore
        adjustTelephoneValue({ target: { value: props.userDetails?.phoneNumber } }, field.id)
      }
    }
    for (let validator of field.FieldValidators) {
      switch (validator.type) {
        case FieldValidationType.Custom:
          // Todo type these.
          if (validator.customValidator === 'FirstAndLastName') {
            rules.push(VuetifyValidation.NameRule(validator.errorMessageTpl))
          } else if (validator.customValidator === 'EmailOrPhone') {
            emailOrPhoneFields.add(field.id)
            rules.push(CheckEmailAndPhone(validator.errorMessageTpl))
          } else if (validator.customValidator === 'DecimalNumber')(
            rules.push(VuetifyValidation.DecimalNumberValidation(validator.errorMessageTpl))
          )
          break
        case FieldValidationType.Email:
          rules.push(VuetifyValidation.EmailValidation(validator.errorMessageTpl))
          field.type = FieldType.Email
          break
        case FieldValidationType.Phone:
          rules.push(VuetifyValidation.PhoneNumberValidation(validator.errorMessageTpl))
          field.type = FieldType.Phone
          break
        case FieldValidationType.MaxLength:
          if (
            validator.value != null &&
            validator.value != undefined &&
            isValidNumber(validator.value)
          ) {
            rules.push(
              VuetifyValidation.MaxLengthValidation(
                parseInt(validator.value),
                validator.errorMessageTpl
              )
            )
          }
          break
        case FieldValidationType.MaxValue:
          if (
            validator.value != null &&
            validator.value != undefined &&
            isValidNumber(validator.value)
          ) {
            rules.push(
              VuetifyValidation.MaxValueValidation(
                parseInt(validator.value),
                validator.errorMessageTpl
              )
            )
          }
          break
        case FieldValidationType.MinLength:
          if (
            validator.value != null &&
            validator.value != undefined &&
            isValidNumber(validator.value)
          ) {
            rules.push(
              VuetifyValidation.MinLengthValidation(
                parseInt(validator.value),
                validator.errorMessageTpl
              )
            )
          }
          break
        case FieldValidationType.MinValue:
          if (
            validator.value != null &&
            validator.value != undefined &&
            isValidNumber(validator.value)
          ) {
            rules.push(
              VuetifyValidation.MinValueValidation(
                parseInt(validator.value),
                validator.errorMessageTpl
              )
            )
          }
          break
        case FieldValidationType.Required:

          if (field.type == FieldType.Number) {
            rules.push(VuetifyValidation.NumberRequiredValidationField(validator.errorMessageTpl))
          } else {
            rules.push(VuetifyValidation.RequiredValidationField(validator.errorMessageTpl))
          }
          break
      }
    }
    validationRules.set(field.id, rules)
  }
}
if (props.use === 'Missed') {
  data.value.message = ''
  // TODO: Make these not random strings, maybe load from API?
  const rules: ValidationRule[] = [
    VuetifyValidation.MaxLengthValidation(1025, 'Message must not exceed 1025 characters.'),
    VuetifyValidation.MinLengthValidation(10, 'Message must be longer than 10 characters.'),
    VuetifyValidation.RequiredValidationField('Message is required.')
  ]
  validationRules.set('message', rules)
}

const focusInput = (ev: MouseEvent) => {
  if (ev.currentTarget) {
    let inputWrapper: HTMLDivElement = ev.currentTarget as HTMLDivElement
    inputWrapper.querySelector('input')?.focus()
    inputWrapper.querySelector('textarea')?.focus()
  }
}

onBeforeMount(() => {
  ;(async () => {
    try {
      const response = await fetch('https://ip2c.org/s')
      const result = (await response.text()).toString()
      if (!result || result[0] !== '1') {
        throw new Error('IP2C is Down.')
      }
      defaultCountryCode = result.substring(2, 4) as CountryCode
    } catch (e) {
      captureException(e);
    }
    // Saving for Version 2
    // loadDefaultValues(); // If this depends on defaultCountryCode, call it here.
  })()
  // Other initialization logic can go here
  // If loadDefaultValues() doesn't depend on the fetched country code, call it here.
})

onMounted(() => {
  if (inputForm) {
    const input = inputForm.value?.querySelector('input')
    if (input) {
      input.focus()
    } else {
      inputForm.value?.querySelector('textarea')?.focus()
    }
  }
  if (
    props.userDetails?.firstName &&
    props.userDetails?.lastName &&
    (props.userDetails?.phoneNumber || props.userDetails?.emailAddress) &&
    !props.formSubmission
  ) {
    setTimeout(async () => {
      await submit()
    }, 1)
  }
})

var time: any

const resetToHomepageOnInactivity = () => {
  window.location.reload()
}

const resetTimer = () => {
  clearTimeout(time)
  time = setTimeout(resetToHomepageOnInactivity, 180000)
}

const addEventListeners = () => {
  document.addEventListener('mousemove', resetTimer)
  document.addEventListener('mousedown', resetTimer) // touchscreen presses
  document.addEventListener('touchstart', resetTimer)
  document.addEventListener('click', resetTimer) // touchpad clicks
  document.addEventListener('keydown', resetTimer) // onkeypress is deprecated
  document.addEventListener('scroll', resetTimer, true) // improved; see comments
}

const removeEventListeners = () => {
  document.removeEventListener('mousemove', resetTimer)
  document.removeEventListener('mousedown', resetTimer) // touchscreen presses
  document.removeEventListener('touchstart', resetTimer)
  document.removeEventListener('click', resetTimer) // touchpad clicks
  document.removeEventListener('keydown', resetTimer) // onkeypress is deprecated
  document.removeEventListener('scroll', resetTimer, true) // improved; see comments
}

onMounted(() => {
  if (props.sourceType === SourceType.KIOSK) {
    resetTimer()
    addEventListeners()
  }
})

onBeforeUnmount(() => {
  clearTimeout(time)
  removeEventListeners()
})
onErrorCaptured((err, vm, info) => {
  captureException(err);
})
// const loadDefaultValues = () => {
//   if (props.formSubmission) {
//     for (let field of props.formSubmission.FormSubmissionFields ?? []) {
//       data[field.fieldId] = field.value
//     }
//   }
// }

const checkRulesAgainstData = (rules: ValidationRule[], id: string): boolean | string => {
  let value = data.value[id]
  for (let rule of rules) {
    const result = rule.isValid(value)
    if (result === true) {
      continue
    } else {
      if (id) {
        failedData.value[id] = result
      }
      return result
    }
  }
  if (id) {
    failedData.value[id] = ''
  }
  return true
}

const getFormFieldSubmissionFieldFromData = (
  field: FormField,
  data: any
): Partial<FormSubmissionField> => {
  let type: FieldDataType = FieldDataType.Text
  if (field.type === FieldType.Text || field.type === FieldType.TextArea) {
    type = FieldDataType.Text
  } else if (field.type === FieldType.Date || field.type === FieldType.DateTime) {
    type = FieldDataType.Date
  } else if (field.type === FieldType.Number) {
    type = FieldDataType.Number
  }
  return {
    fieldId: field.id,
    value: data[field.id],
    type: type
  }
}

const setUserDetailsByData = (data: any): void => {
  let userDetails: UserDetails = { firstName: '', lastName: '', emailAddress: '', phoneNumber: '' }
  for (let field of props.form.FormFields) {
    if (field.name.toLocaleLowerCase() === DefaultFieldNames.NameField.toLowerCase()) {
      if (data[field.id]) {
        const split = data[field.id].split(' ')
        if (split.length > 0) {
          userDetails.firstName = split[0]
        }
        if (split.length > 1) {
          userDetails.lastName = split[split.length - 1]
        }
      } else {
        userDetails.firstName = ''
        userDetails.lastName = ''
      }
    } else if (
      field.name.toLocaleLowerCase() === DefaultFieldNames.EmailAddressField.toLowerCase()
    ) {
      userDetails.emailAddress = data[field.id]
    } else if (field.name.toLocaleLowerCase() === DefaultFieldNames.PhoneField.toLowerCase()) {
      userDetails.phoneNumber = data[field.id]
    }
  }
  emits('userDetails', userDetails)
}

const submit = async () => {
  const dataToCheck = data.value
  const rulesToCheck = validationRules
  let isValidForm = true
  for (let field in dataToCheck) {
    const result = checkRulesAgainstData(rulesToCheck.get(field) ?? [], field)
    if (result === true) {
      failedData.value[field] = ''
    } else {
      failedData.value[field] = result
      isValidForm = false
    }
  }
  if (!isValidForm) {
    return
  }
  setUserDetailsByData(dataToCheck)
  formDisabled.value = true
  const formSubmissionFields: Array<Partial<FormSubmissionField>> = []
  for (let field of props.form.FormFields) {
    if (dataToCheck[field.id] != undefined && dataToCheck[field.id] != null) {
      formSubmissionFields.push(getFormFieldSubmissionFieldFromData(field, dataToCheck))
    }
  }
  const formSubmission: Partial<FormSubmission> = {
    formId: props.form.id,
    organizationId: props.form.organizationId,
    FormSubmissionFields: formSubmissionFields as FormSubmissionField[],
    message: dataToCheck.message
  }
  if (props.sourceType === SourceType.KIOSK) {
    formSubmission.kioskId = props.model.id
  } else if (props.sourceType === SourceType.QRCODE) {
    formSubmission.qrCodeId = props.model.id
  } else if (props.sourceType === SourceType.WEBSITE) {
    formSubmission.websiteId = props.model.id
  }
  emits('formSubmit', formSubmission as FormSubmission)
  emits('transition')
}

const leave = () => {
  emits('close')
}
</script>
<style scoped>
@tailwind base;
@tailwind components;
@tailwind utilities;
div {
  font-family: 'Inter Var', 'ui-sans-serif', 'system-ui', 'sans-serif';
}
</style>
