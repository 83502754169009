export enum ConversationInboundStatus {
  AwaitingSpecificPickup = 'AWAITING_SPECIFIC_PICKUP',
  AwaitingAnyPickup = 'AWAITING_ANY_PICKUP',
  InProgress = 'IN_PROGRESS',
  Ended = 'ENDED',
  Missed = 'MISSED'
}

export enum CallStates {
  SEARCHING = 'searching',
  IN_PROGRESS = 'incall',
  MISSED = 'missed',
  ENDED = 'ended',
  ERROR = 'error'
}

export enum SourceType {
  WEBSITE = 'WEBSITE',
  KIOSK = 'KIOSK',
  QRCODE = 'QRCODE',
  SHOPIFY = 'SHOPIFY',
  SMS = 'SMS',
  FORM = 'FORM',
  UNKNOWN = 'UNKNOWN'
}

export enum TextCommands {
  TAKE_SNAPSHOT = 'take-snapshot',
  SNAPSHOT_FAILED = 'snapshot-failed',
  SNAPSHOT_SUCCESS = 'snapshot-successful'
}

export enum CustomElementTags {
  KIOSK_HOME = 'liveswitch-kiosk-home',
  BELL = 'liveswitch-bell',
  POPUP = 'liveswitch-popup',
  WINDOW = 'liveswitch-window',
  WINDOW_DECORATION = 'liveswitch-window-decoration',
  DIALING_IN = 'liveswitch-dialing-in',
  IN_CALL = 'liveswitch-in-call',
  END_CALL = 'liveswitch-end-call',
  PERMISSIONS_CHECK = 'liveswitch-permission-check',
  FORM = 'liveswitch-form'
}

export const sentryTag = 'liveswitch-concierge'