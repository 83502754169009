import type { BubbleConfig } from 'types/BubbleConfig'
import type { UserDetails } from 'types/UserDetails'
import { API } from '@/utility/Api'
import { addBreadcrumb, BellButton, captureException, setContext, WindowPopup } from '@/bubble'
import { LiveSwitchConference } from './LiveSwitchConference'
import { SourceType } from '@/utility/Constants'
import type { Kiosk } from 'types/Kiosk'
import type { QRCode } from 'types/QRCode'
import type { Website } from 'types/Website'
import type { LastActiveCall } from 'types/LastActiveCall'
import { Session } from './Session'
import { setTag } from '@sentry/vue'

export class LiveSwitchConcierge {
  api = new API()
  codename: string = ''
  config: BubbleConfig | undefined = undefined
  isStartingBubble: boolean = false
  public async loadInitialConfig(
    codename: string,
    lastActiveCallInformation: LastActiveCall | undefined = undefined
  ) {
    try {
      addBreadcrumb({
        category: 'LiveSwitchConcierge',
        message: 'Called load initial config.',
        level: 'info'
      })
      setContext('loadInitialConfig', {
        codename,
        lastActiveCallInformation
      })
      const params: any = {}
      if (lastActiveCallInformation && lastActiveCallInformation.conversation) {
        params.conversationId = lastActiveCallInformation.conversation.id
      }
      const resp = await this.api.getAuth(`/public/website/${codename}/bubbleConfig`, params)
      if (resp.ok) {
        resp.data.codename = codename
        setContext('config', resp.data)
        if (resp.data.isConversationActive) {
          if (!resp.data.isConversationFull) {
            addBreadcrumb({
              category: 'LiveSwitchConcierge',
              message: 'Starting conversation based on reconnection.',
              level: 'info'
            })
            this.startConversation(codename, resp.data, undefined)
          } else {
            // If the session is full, you are already connected on another tab.
            // This happens if you duplicate the tab, in which case the same SessionStorage is used.
            addBreadcrumb({
              category: 'LiveSwitchConcierge',
              message: 'Removed existing session.',
              level: 'info'
            })
            Session.removeExistingSession()
          }
        } else {
          addBreadcrumb({
            category: 'LiveSwitchConcierge',
            message: 'Removed existing session.',
            level: 'info'
          })
          Session.removeExistingSession()
        }
        return resp.data as BubbleConfig
      } else {
        addBreadcrumb({
          category: 'LiveSwitchConcierge',
          message: 'Failed to load initial config',
          level: 'error'
        })
        const text = await resp.text()
        throw new Error(text ? text : 'Failed to load initial config.')
      }
    } catch (e) {
      captureException(e)
    }
    return null
  }
  public async loadBubble(codename: string) {
    try {
      setContext('loadBubbleConfig', codename)
      addBreadcrumb({
        category: 'LiveSwitchConcierge',
        message: 'Called load bubble.',
        level: 'info'
      })
      let lastActiveCallInformation: LastActiveCall | undefined = Session.getSession()
      const config = await this.loadInitialConfig(codename, lastActiveCallInformation)
      if (config && config.showOnLoad) {
        this.config = config
        const bell = new BellButton({ config })
        bell.setAttribute('position', config?.position ?? 'right')
        bell.addEventListener('start', () => {
          if (!this.isStartingBubble) {
            this.isStartingBubble = true
            this.startConversation(codename, config)
          }
        })
        document.body.appendChild(bell)
        addBreadcrumb({
          category: 'LiveSwitchConcierge',
          message: 'Appended bell to page.',
          level: 'info'
        })
      } else if (!config) {
        throw new Error(`Bubble config didn't load.`)
      } else if (!config.showOnLoad) {
        addBreadcrumb({
          category: 'LiveSwitchConcierge',
          message: 'Bubble not shown on load.',
          level: 'info'
        })
      }
    } catch (e) {
      captureException(e)
    }
  }
  public async loadWebsite(codename: string) {
    try {
      addBreadcrumb({
        category: 'LiveSwitchConcierge',
        message: 'Called load website.',
        level: 'info'
      })
      setContext('websiteId', { codename })
      setTag('running', 'website')
      const resp = await this.api.getAuth(`/websites/${codename}/public`, {})
      if (resp.ok) {
        setContext('website', resp.data)
        addBreadcrumb({
          category: 'LiveSwitchConcierge',
          message: 'Loaded website.',
          level: 'info'
        })
        return resp.data as Website
      } else {
        const text = await resp.text()
        throw new Error(text ? text : 'Failed to load Website.')
      }
    } catch (e) {
      captureException(e, {
        captureContext: {
          extra: {
            codename,
            type: 'Webiste'
          }
        }
      })
    }
    return null
  }
  public async loadKiosk(id: string): Promise<Kiosk | null> {
    try {
      addBreadcrumb({
        category: 'LiveSwitchConcierge',
        message: 'Called load kiosk.',
        level: 'info'
      })
      setContext('kioskid', { id })
      setTag('running', 'kiosk')
      const resp = await this.api.getAuth(`/kiosks/${id}/public`, {})
      if (resp.ok) {
        setContext('kiosk', resp.data)
        addBreadcrumb({ category: 'LiveSwitchConcierge', message: 'Loaded kiosk.', level: 'info' })
        return resp.data as Kiosk
      } else {
        const text = await resp.text()
        throw new Error(text ? text : 'Failed to load kiosk.')
      }
    } catch (e) {
      captureException(e, {
        captureContext: {
          extra: {
            id,
            type: 'Kiosk'
          }
        }
      })
    }
    return null
  }
  public async loadQRCode(id: string): Promise<QRCode | null> {
    try {
      addBreadcrumb({
        category: 'LiveSwitchConcierge',
        message: 'Called load QR Code.',
        level: 'info'
      })
      setContext('qrcodeid', { id })
      setTag('running', 'qrcode')
      const resp = await this.api.getAuth(`/qr-codes/${id}/public`, {})
      if (resp.ok) {
        setContext('qrcode', resp.data)
        addBreadcrumb({
          category: 'LiveSwitchConcierge',
          message: 'Loaded QR code.',
          level: 'info'
        })
        return resp.data as QRCode
      } else {
        const text = await resp.text()
        throw new Error(text ? text : 'Failed to load QRCode.')
      }
    } catch (e) {
      captureException(e, {
        captureContext: {
          extra: {
            id,
            type: 'QRCode'
          }
        }
      })
    }
    return null
  }
  public async startConversation(
    codename: string,
    config: BubbleConfig,
    userDetails?: UserDetails
  ) {
    try {
      addBreadcrumb({
        category: 'LiveSwitchConcierge',
        message: 'Called start conversation.',
        level: 'info'
      })
      const model: Website | null = await this.loadWebsite(codename)
      if (model === null || model === undefined) {
        throw new Error('Failed to connect to api service. Please try again later.')
      }
      if (!config || !config.id) {
        let c = await this.loadInitialConfig(codename)
        if (c) {
          c = { ...c, ...config }
          config = c
        } else {
          throw new Error('Failed to load bubble config')
        }
      }
      const popup = new WindowPopup()
      popup.setAttribute('position', config?.position ?? 'right')
      popup.style.display = 'flex'
      popup.style.flexDirection = 'column'
      popup.style.backgroundColor = 'white'
      document.body.appendChild(popup)
      addBreadcrumb({
        category: 'LiveSwitchConcierge',
        message: 'Ready to start internal conference.',
        level: 'info'
      })
      await this.startInternalConference(popup, config, model!, userDetails, SourceType.WEBSITE)
    } catch (e) {
      captureException(e, {
        captureContext: {
          extra: {
            codename,
            config,
            userDetails,
            method: 'startConversation'
          }
        }
      })
    } finally {
      this.isStartingBubble = false
    }
  }
  public async startInternalConference(
    rootComponent: HTMLElement,
    config: BubbleConfig,
    model: Kiosk | QRCode | Website,
    userDetails?: UserDetails,
    sourceType: SourceType = SourceType.WEBSITE
  ) {
    try {
      addBreadcrumb({
        category: 'LiveSwitchConcierge',
        message: 'Called internal conference.',
        level: 'info'
      })
      new LiveSwitchConference(rootComponent, config, model, userDetails, sourceType)
    } catch (e) {
      captureException(e, {
        captureContext: {
          extra: {
            rootComponent,
            config,
            model,
            sourceType,
            userDetails,
            method: 'startInternalConference'
          }
        }
      })
    }
  }
}
